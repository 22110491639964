import { Box, IconButton, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export const TopMenu = ({ setOpen }) => {
  const theme = useTheme();
  return (
    <Box
      position={"absolute"}
      top={0}
      width={theme.topMenu.width}
      height={"8%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      padding={theme.topMenu.padding}
    >
      <img
        height={theme.topMenu.imgHeight}
        src={theme.topMenu.imgSrc}
        alt="Water tales logo"
      />
      <IconButton
        sx={{ pointerEvents: "auto" }}
        width={"100%"}
        onClick={() => setOpen(true)}
      >
        <MenuIcon fontSize="large" sx={{ color: "#406580" }} />
      </IconButton>
    </Box>
  );
};
