import { Box, CircularProgress, Typography } from "@mui/material";

export const CircularProgressWithLabel = ({ loadingProgress }) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        size={150}
        variant="determinate"
        value={loadingProgress}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          fontWeight={"bold"}
          fontSize={"1.5rem"}
          variant="caption"
          component="div"
          color="text.secondary"
        >
          {`${loadingProgress}%`}
        </Typography>
      </Box>
    </Box>
  );
};
