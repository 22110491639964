import { Box, Typography, Button, useTheme } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useAreaSelectSettings } from "../../../../../../Contexts/AreaSelectContext";
import { useSubAreaSettings } from "../../../../../../Contexts/SubAreaContext";

export const AreaButton = ({ area }) => {
  const { setAreaSelected, rotateToArea } = useAreaSelectSettings();
  const { setCategorySelected } = useSubAreaSettings();
  const theme = useTheme();

  return (
    <Button
      sx={{ textTransform: "none", minWidth: "150px" }}
      onClick={() => {
        setAreaSelected(area);
        setCategorySelected("Introduction");
        rotateToArea(area);
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <LocationOnOutlinedIcon
          style={theme.nextScenarioNonMobile.nextScenarioButton}
        />
        <Typography
          fontWeight={600}
          color={"#406580"}
          fontSize={theme.nextScenarioNonMobile.nextScenarioButton.textSize}
        >
          {area.charAt(0).toUpperCase() + area.slice(1)}
        </Typography>
      </Box>
    </Button>
  );
};
