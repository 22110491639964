import { Box, Typography, useTheme } from "@mui/material";
import { useAreaSelectSettings } from "../../../../../../Contexts/AreaSelectContext";
import { AreaButton } from "./AreaButton";
import { HereButton } from "./HereButton";
import { RedLine } from "./RedLine";

export const NextScenarioNonMobile = () => {
  const { areaSelected, areas } = useAreaSelectSettings();
  const theme = useTheme();

  return (
    <span
      style={{
        width: theme.nextScenarioNonMobile.width,
        height: theme.nextScenarioNonMobile.height,
        filter: "drop-shadow(0px 1.5px 2px #000000)",
        position: "absolute",
        bottom: -10,
        right: -5,
      }}
    >
      <Box
        width={"100%"}
        height={"100%"}
        sx={{
          borderTopLeftRadius: "50px",
          transform: "rotate(-1deg)",
        }}
        bgcolor={"#F8FFFD"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Box width={"80%"}>
          <Typography
            variant="h1"
            fontFamily={"Montserrat Alternates"}
            fontWeight={"bold"}
            color={"#406580"}
            fontSize={theme.nextScenarioNonMobile.headingFontSize}
          >
            Visit the next scenario
          </Typography>
        </Box>
        <Box
          width={"90%"}
          display={"flex"}
          justifyContent={"space-between"}
          position={"relative"}
        >
          {areas.map((area, i) => {
            if (area === areaSelected) {
              return <HereButton key={area + i} />;
            } else return <AreaButton key={area + i} area={area} />;
          })}
          <RedLine />
        </Box>
      </Box>
    </span>
  );
};
