import { Box, Typography, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const HereButton = () => {
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      minWidth={"150px"}
    >
      <LocationOnIcon style={theme.nextScenarioNonMobile.nextScenarioButton} />
      <Typography
        fontWeight={600}
        color={"#406580"}
        fontSize={theme.nextScenarioNonMobile.nextScenarioButton.textSize}
      >
        You are here
      </Typography>
    </Box>
  );
};
