import { createContext, useContext, useState } from "react";

const ModelLoadedContext = createContext();

export const ModelLoadedContextProvider = ({ children }) => {
  const [modelLoaded, setModelLoaded] = useState(false);

  return (
    <ModelLoadedContext.Provider value={{ modelLoaded, setModelLoaded }}>
      {children}
    </ModelLoadedContext.Provider>
  );
};

export const useModelLoaded = () => {
  const context = useContext(ModelLoadedContext);

  if (context === undefined) {
    throw new Error(
      "useModelLoaded mst be used witin a ModelLoadedContextProvider",
    );
  }
  return context;
};
