import { Box, useTheme } from "@mui/material";
import { useModelLoaded } from "../../../Contexts/ModelLoadedContext";
import { keyframes } from "@mui/material";

export const AboutIsland = () => {
  const { modelLoaded } = useModelLoaded();
  const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;
  const theme = useTheme();

  return (
    <Box
      position={"absolute"}
      width={theme.aboutIsland.width}
      height={"90%"}
      top={"0"}
      left={"0"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      sx={{
        opacity: modelLoaded ? 1 : 0.25,
        transition: "opacity 0.5s ease-in-out",
        animation: `${floatAnimation} 3s ease-in-out infinite`,
        pointerEvents: "none",
      }}
    >
      <img
        style={{ pointerEvents: "auto" }}
        width={"75%"}
        src="Assets/Svgs/About The Programme.png"
        alt="About the programme island"
      />
    </Box>
  );
};
