import { Box, Typography, useTheme } from "@mui/material";

export const AboutTheProgramme = () => {
  const theme = useTheme();

  return (
    <Box
      padding={"30px"}
      width={"calc(100% - 60px)"}
      height={"40%"}
      bgcolor={"#FFFAEA"}
      sx={{
        clipPath:
          "polygon(0% 0%, 0% 100%, 29% 89%, 62% 99%, 100% 90%, 100% 0%)",
      }}
    >
      <Box width={theme.aboutTheProgramme.imgWidth} height={"15%"}>
        <img
          width={"100%"}
          src="Assets/Svgs/Water Tales Logo.svg"
          alt="Water Tales Logo"
        />
      </Box>
      <Box
        width={"100%"}
        height={"85%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Typography
          variant="h1"
          fontWeight={"bold"}
          textAlign={"center"}
          fontSize={theme.aboutTheProgramme.headingFontSize}
        >
          <span style={{ color: "#406580" }}>
            Find out how wastewater is creating a{" "}
          </span>
          <span style={{ color: "#EA2E5B" }}>healthier community</span>
        </Typography>
        <Typography
          fontSize={theme.aboutTheProgramme.subHeadingFontSize}
          color={"#14335E"}
          variant="h2"
          fontWeight={600}
          width={theme.aboutTheProgramme.subHeadingWidth}
          textAlign={"center"}
          paddingTop={"10px"}
        >
          Find out how wastewater could improve the social, economic,
          environmental well-being of our community
        </Typography>
      </Box>
    </Box>
  );
};
