import { Box, Typography, Button, useTheme } from "@mui/material";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";

export const FindOutMore = () => {
  const theme = useTheme();

  return (
    <span
      style={{
        width: theme.findOutMore.spanStyling.width,
        height: theme.findOutMore.spanStyling.height,
        minHeight: theme.findOutMore.spanStyling.height,
        filter: "drop-shadow(0px 1.5px 2px #000000)",
        paddingTop: theme.findOutMore.paddingTop,
      }}
    >
      <Box
        width={"100%"}
        height={"100%"}
        bgcolor={"#FFEFE4"}
        sx={{
          clipPath: "polygon(0 0, 100% 12%, 100% 100%, 0% 100%)",
        }}
      >
        <Box
          paddingTop={"50px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"calc(100% - 50px)"}
          flexDirection={"column"}
          position={"relative"}
        >
          <Box width={"100%"} height={"100%"} position={"absolute"} bottom={0}>
            <img
              style={{ objectFit: "cover" }}
              width={"100%"}
              height={"100%"}
              src={"Assets/Svgs/Find out More.svg"}
              alt="about WBE"
            />
          </Box>

          <Box
            position={"absolute"}
            top={0}
            left={0}
            paddingTop={"30px"}
            width={"100%"}
            height={"calc(100% - 30px)"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
          >
            <Box
              height={"calc(100% - 50px)"}
              width={"calc(100% - 60px)"}
              paddingLeft={"30px"}
              paddingRight={"30px"}
              paddingTop={theme.findOutMore.wrapperBoxPaddingTop}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography
                variant={"h1"}
                fontWeight={"bold"}
                color={"#406580"}
                paddingBottom={"20px"}
                fontSize={theme.findOutMore.heading}
                paddingTop={theme.findOutMore.headingPaddingTop}
              >
                FIND OUT MORE
              </Typography>
              <Box
                width={"100%"}
                height={"100%"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-around"}
              >
                <Button
                  variant="outlined"
                  startIcon={
                    <MessageOutlinedIcon
                      style={{ fontSize: theme.findOutMore.buttonTextSize }}
                    />
                  }
                  sx={{
                    fontSize: theme.findOutMore.buttonTextSize,
                    bgcolor: "#f9f5e7",
                    borderColor: "#FBCBB0",
                    border: "3px solid #FBCBB0",
                    color: "#14335E",
                    borderRadius: "50px",
                    height: theme.findOutMore.buttonHeight,
                    "&.MuiButton-root:hover": {
                      backgroundColor: "#14586B",
                      color: "white",
                      border: "3px solid #ffffff",
                    },
                  }}
                >
                  Contact Us
                </Button>
                <Button
                  sx={{
                    fontSize: theme.findOutMore.buttonTextSize,
                    bgcolor: "#f9f5e7",
                    borderColor: "#FBCBB0",
                    border: "3px solid #FBCBB0",
                    color: "#14335E",
                    borderRadius: "50px",
                    height: theme.findOutMore.buttonHeight,
                    "&.MuiButton-root:hover": {
                      backgroundColor: "#14586B",
                      color: "white",
                      border: "3px solid #ffffff",
                    },
                  }}
                  variant="outlined"
                  startIcon={
                    <CollectionsBookmarkOutlinedIcon
                      style={{ fontSize: theme.findOutMore.buttonTextSize }}
                    />
                  }
                >
                  Resources
                </Button>
              </Box>
              <Box
                height={theme.findOutMore.findOutButtonsWrapperBox.height}
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"flex-end"}
                paddingBottom={
                  theme.findOutMore.findOutButtonsWrapperBox.paddingBottom
                }
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    backgroundColor: "#7127ba",
                    fontSize: theme.findOutMore.exploreButtonTextSize,
                    borderRadius: "50px",
                    textTransform: "none",
                    "&.MuiButton-root:hover": {
                      backgroundColor: "#14586B",
                      color: "white",
                    },
                  }}
                >
                  Explore
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </span>
  );
};
