import { Box, Button, useTheme } from "@mui/material";

export const CategoriesNonMobile = ({ infoTitle, setInfoTitle }) => {
  const theme = useTheme();

  const onClick = (area) => {
    setInfoTitle(area);
  };

  const categories = ["overview", "what", "how", "why"];
  return (
    <>
      <Box width={"100%"} height={"15%"} display={"flex"}>
        {categories.map((category) => (
          <Button
            key={category}
            sx={{
              flexGrow: 1,
              bgcolor: infoTitle === category ? "" : "#FFEFE4",
              color: "#7a4191",
              fontFamily: "Montserrat",
              fontSize: theme.categoriesNonMobile.fontSize,
              fontWeight: "bold",
            }}
            onClick={() => onClick(category)}
          >
            {category}
          </Button>
        ))}
      </Box>
    </>
  );
};
