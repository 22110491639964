import { Box, IconButton, Typography } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { useState } from "react";

export const CategoriesMobile = ({ infoTitle, setInfoTitle }) => {
  const categories = ["overview", "what", "how", "why"];
  const [forwardButtonTitle, setForwardButtonTitle] = useState("What");
  const [backButtonTitle, setbackButtonTitle] = useState("");

  const onClickForwards = () => {
    const currentIndex = categories.indexOf(infoTitle);
    if (currentIndex < categories.length - 1) {
      const nextCategory = categories[currentIndex + 1];
      setInfoTitle(nextCategory);
      setForwardButtonTitle(categories[currentIndex + 2] || "");
      setbackButtonTitle(categories[currentIndex]);
    }
  };

  const onClickBack = () => {
    const currentIndex = categories.indexOf(infoTitle);
    if (currentIndex > 0) {
      const previousCategory = categories[currentIndex - 1];
      setInfoTitle(previousCategory);
      setbackButtonTitle(categories[currentIndex - 2] || "");
      setForwardButtonTitle(categories[currentIndex]);
    }
  };

  return (
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      paddingBottom={"5px"}
    >
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {infoTitle !== "overview" && (
          <>
            <IconButton sx={{ color: "#406580" }} onClick={() => onClickBack()}>
              <ArrowCircleLeftIcon color="#406580" />
            </IconButton>
            <Typography color={"#406580"}>{backButtonTitle}</Typography>
          </>
        )}
      </Box>

      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {infoTitle !== "why" && (
          <>
            <IconButton
              sx={{ color: "#406580" }}
              onClick={() => onClickForwards()}
            >
              <ArrowCircleRightIcon color="#406580" />
            </IconButton>
            <Typography color={"#406580"}>{forwardButtonTitle}</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
