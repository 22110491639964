import { Box, Typography, Button, useTheme } from "@mui/material";

export const AboutWastewater = () => {
  const theme = useTheme();
  return (
    <span
      style={{
        width: theme.aboutWastewater.width,
        height: theme.aboutWastewater.height,
        minHeight: theme.aboutWastewater.height,
        filter: "drop-shadow(0px 1.5px 2px #000000)",
        paddingBottom: theme.aboutWastewater.paddingBottom,
      }}
    >
      <Box
        width={"100%"}
        height={"100%"}
        bgcolor={"#FCEBEB"}
        sx={{
          clipPath: "polygon(0 5%, 100% 0, 100% 100%, 3% 100%)",
          transform: "rotate(-1deg)",
        }}
      >
        <Box
          paddingTop={"50px"}
          sx={{ transform: "rotate(1deg)" }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"calc(100% - 50px)"}
          flexDirection={"column"}
          position={"relative"}
        >
          <Box
            width={"100%"}
            height={"100%"}
            position={"absolute"}
            bottom={-17}
            right={-10}
          >
            <img
              style={{ objectFit: "cover" }}
              height={"100%"}
              width={"100%"}
              src={"Assets/Svgs/About wastewater monitoring.svg"}
              alt="about WBE"
            />
          </Box>

          <Box
            position={"absolute"}
            top={0}
            left={0}
            width={"100%"}
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
          >
            <Box
              height={"calc(75% - 60px)"}
              paddingTop={"60px"}
              paddingLeft={"50px"}
              paddingRight={"50px"}
            >
              <Typography
                variant={"h1"}
                fontSize={theme.aboutWastewater.heading}
                fontWeight={"bold"}
                color={"#406580"}
                paddingBottom={theme.aboutWastewater.headingBottomPadding}
              >
                ABOUT WASTEWATER MONITORING
              </Typography>

              <Typography
                variant={"h2"}
                color={"#406580"}
                fontWeight={"500"}
                fontSize={theme.aboutWastewater.subHeading}
              >
                This project is based on the existing application of wastewater
                monitoring around the world.
              </Typography>
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                sx={{
                  width: "75%",
                  backgroundColor: "#7127ba",
                  fontSize: theme.aboutWastewater.exploreButtonTextSize,
                  borderRadius: "50px",
                  textTransform: "none",
                  "&.MuiButton-root:hover": {
                    backgroundColor: "#14586B",
                    color: "white",
                  },
                }}
              >
                Explore
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </span>
  );
};
