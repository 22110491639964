import { cloneElement } from "react";
import { Box, Button, useTheme, Typography } from "@mui/material";
import { useSubAreaSettings } from "../../../../../Contexts/SubAreaContext";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useAreaSelectSettings } from "../../../../../Contexts/AreaSelectContext";

export const AreaButtons = () => {
  const { setCategorySelected, categorySelected, currentSubArea } =
    useSubAreaSettings();
  const { onExitClick } = useAreaSelectSettings();
  const theme = useTheme();

  return (
    <>
      <Box
        position={"absolute"}
        width={"25%"}
        height={theme.areaButtons.height}
        top={theme.areaButtons.top}
        left={"0"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
      >
        <Box
          height={"20%"}
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            variant="outlined"
            sx={{
              border: 2,
              borderColor: "#7A4191",
              minWidth: theme.areaButtons.backButtonMinWidth,
              pointerEvents: "auto",
              color: "#14335E",
              borderRadius: "50px",
              height: theme.areaButtons.backButtonHeight,
              "&.MuiButton-root:hover": {
                border: 2,
              },
              textTransform: "none",
              fontSize: "1rem",
            }}
            onClick={() => onExitClick("subArea")}
          >
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"200px"}
            >
              <Box
                width={"20%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <KeyboardReturnIcon
                  sx={{
                    color: "red",
                    fontSize: theme.areaButtons.backButtonFontSize,
                  }}
                />
              </Box>
              <Box width={"80%"}>
                <Typography fontSize={theme.areaButtons.backButtonFontSize}>
                  Back to Home Island
                </Typography>
              </Box>
            </Box>
          </Button>
        </Box>
        <Box
          width={"25%"}
          height={"80%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          {currentSubArea.map((button) => {
            return (
              <Button
                key={button.name}
                variant="contained"
                sx={{
                  fontWeight: 600,
                  minWidth: theme.areaButtons.minWidth,
                  pointerEvents: "auto",
                  backgroundColor:
                    categorySelected === button.name ? "#14586B" : "#FFF5EB",
                  color:
                    categorySelected === button.name ? "#ffffff" : "#7A4191",
                  borderRadius: "50px",
                  height: theme.areaButtons.buttonHeight,
                  "&.MuiButton-root:hover": {
                    backgroundColor: "#14586B",
                    color: "white",
                  },
                  textTransform: "none",
                  fontSize: theme.areaButtons.fontSize,
                }}
                onClick={() => setCategorySelected(button.name)}
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    width={"20%"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {/* {button.icon} */}
                    {cloneElement(button.icon, { style: { fontSize: "3rem" } })}
                  </Box>
                  <Box width={"80%"}>{button.name}</Box>
                </Box>
              </Button>
            );
          })}
        </Box>
      </Box>
    </>
  );
};
