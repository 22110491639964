import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import DvrOutlinedIcon from "@mui/icons-material/DvrOutlined";
import LocalPharmacyOutlinedIcon from "@mui/icons-material/LocalPharmacyOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import EmojiNatureOutlinedIcon from "@mui/icons-material/EmojiNatureOutlined";
import YardOutlinedIcon from "@mui/icons-material/YardOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import DirectionsBikeOutlinedIcon from "@mui/icons-material/DirectionsBikeOutlined";
import ElderlyWomanOutlinedIcon from "@mui/icons-material/ElderlyWomanOutlined";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import AirplanemodeActiveOutlinedIcon from "@mui/icons-material/AirplanemodeActiveOutlined";

export const healthSubAreas = [
  {
    name: "Introduction",
    icon: <ArticleOutlinedIcon />,
    info: {
      overview:
        "Understanding how our hospitals are responding to health challenges is crucial to build an effective healthcare system. Integrating the information we obtain about antimicrobial resistance, disease prevalence both in our communities and in the hospitals helps hospitals allocate resource and plan, leading to a more resilient healthcare system.",
      what: "",
      how: "",
      why: "",
    },
  },
  {
    name: "GP Clinic",
    icon: <LocalHospitalOutlinedIcon />,
    info: {
      overview:
        "Wastewater data gives GPs a wealth of information which can influence their diagnosing and prescribing practices. Acting on epidemiology data such as the prevalence of infections and the health of communities, they can make more informed decisions and provide more informed care for their patients.",
      what: "Monitoring for infectious disease, prevalence, AMR, nutrition and disease markers including pharmaceutical and illicit drug use in communities.",
      how: "Community level wastewater monitoring",
      why: "Providing wastewater data to GP practices would enable them to make more informed decisions, taking into consideration the health of the communities they are working in. Understanding the prevalence of antimicrobial resistance and the geography of outbreaks enables more effective prescribing and remote diagnosis to prevent the spread of disease in communities.",
    },
  },
  {
    name: "Digital Doctor",
    icon: <DvrOutlinedIcon />,
    info: {
      overview: "",
      what: "",
      how: "",
      why: "",
    },
  },
  {
    name: "ICU Unit",
    icon: <LocalPharmacyOutlinedIcon />,
    info: {
      overview:
        "Intensive care units are a hotspot for hospital acquired infections. Monitoring for these has the potential to reduce bed usage in these units whilst saving lives.",
      what: "Monitoring for hospital acquired infections",
      how: "Near-source monitoring of wastewater in Hospitals.",
      why: "Hospital acquired infections cost healthcare providers millions of pounds and are the cause of mortality for many patients every year. Early detection of hospital acquired infection spread within these units would mean safeguards and additional controls can be implemented in these units at the earliest possible opportunity to save lives.",
    },
  },
  {
    name: "Pop-up Clinic",
    icon: <RoomOutlinedIcon />,
    info: {
      overview: "",
      what: "",
      how: "",
      why: "",
    },
  },
];

export const environmentSubAreas = [
  {
    name: "Introduction",
    icon: <EmojiNatureOutlinedIcon />,
    info: {
      overview:
        "Monitoring of streets, parks and cities can reveal the hidden species we live among, the health of our livestock and the impacts of our infrastructure on biodiversity and ecosystem health.",
      what: "",
      how: "",
      why: "",
    },
  },
  {
    name: "Animal health",
    icon: <LocalHospitalOutlinedIcon />,
    info: {
      overview:
        "Monitoring animal and livestock health enable the detection and treatment of zoonotic infectious diseases before they can become a problem for livestock and human health.",
      what: "Zoonotic infections such as Avian influenza, Bovine Tuberculosis, Sheep Scrapie, Foot and Mouth Disease & Mad Cow Disease but also many more.",
      how: "Monitoring agricultural run-off waste, wastewater and rivers close to farms.",
      why: "Annually, £230 billion is lost to the global economy because of zoonotic diseases involving livestock. Using wastewater-based epidemiology we can help us detect these infections in our animal populations early and treat them early. We can also help reduce the rate of cross-over into humans.",
    },
  },
  {
    name: "Crop health",
    icon: <YardOutlinedIcon />,
    info: {
      overview:
        "Monitoring crop health enables us to protect our food security and implement early control measures to prevent the spread of diseases in plant populations.",
      what: "The microorganisms that cause Irish potato late blight, Dutch elm disease, wheat stem rust.",
      how: "Monitoring rivers and agricultural-run off",
      why: "Our food security is crucial for our survival. Monitoring the spread of these diseases allows us to act quickly to intervene and help our agricultural sector to continue thriving.",
    },
  },
  {
    name: "Environmental health & pollution",
    icon: <HealthAndSafetyOutlinedIcon />,
    info: {
      overview:
        "Monitoring the health of our environment and rivers is crucial to improve the environment around us, conserve species and promote citizen science.",
      what: "Monitoring for markers which indicate different animal, fish and insect populations.",
      how: "Monitoring wastewater, rivers and water bodies",
      why: "Maintaining biodiversity and species diversity is crucial to a healthy ecosystem. By monitoring for specific wildlife and monitoring pollution, we can implement changes and policies which can help restore our natural environment.",
    },
  },
  {
    name: "Education Activities",
    icon: <SchoolOutlinedIcon />,
    info: {
      overview: "",
      what: "",
      how: "",
      why: "",
    },
  },
];

export const communitySubAreas = [
  {
    name: "Introduction",
    icon: <PeopleAltOutlinedIcon />,
    areaCameraSettings: "90deg 75deg 50%",
    info: {
      overview:
        "Monitoring capabilities at the community scale could enable new insights into the health and wellbeing of an area and inform local urban responses that improve health and address health inequity.",
      what: "",
      how: "",
      why: "",
    },
  },
  {
    name: "School",
    icon: <SchoolOutlinedIcon />,
    info: {
      overview:
        "Monitoring the health of the young people in our schools is paramount to ensure minimal disruption of education and protection of their health and wellbeing.",
      what: "Monitoring for key infectious disease markers which could contribute to outbreaks in schools such as COVID-19, norovirus, influenza, and RSV. Furthermore monitoring nutrition at schools.",
      how: "Wastewater monitoring",
      why: "Minimising disruption to education is crucial. Early detection of infectious diseases in schools would enable campaigns such as increased encouragement for hand washing to minimise their spread. Additionally, understanding the nutrition and eating habits in our schools can help us promote healthy eating campaigns in educational institutions which need it most.",
    },
  },
  {
    name: "Residential Area",
    icon: <HomeWorkOutlinedIcon />,
    info: {
      overview:
        "Monitoring and understanding the health and needs of our communities is crucial to planning and infrastructure design decisions in areas where the smallest changes can have big impacts on communities.",
      what: "Monitoring community health through pharmaceutical drug use and monitoring for health markers provides an indication of the physical and mental health of our communities.",
      how: "Monitoring pharmaceutical use and health markers in wastewater at the municipal level",
      why: "Decision making for infrastructure and design of our built environment relies on data and knowledge. Using WBE we can understand the health of our communities to better design strategies and interventions which can better meet their requirements.",
    },
  },
  {
    name: "Shops from high street",
    icon: <ShoppingBagOutlinedIcon />,
    info: {
      overview:
        "Good nutrition and healthy eating is an important part of a healthy society. Monitoring markers for nutrition and health in wastewater would help policy makers make decisions about how best to promote healthy eating in communities.",
      what: "Monitoring nutrition markers such as the breakdown products of food consumed.",
      how: "Community level monitoring in wastewater",
      why: "Improving the nutrition and healthy eating habits of our society is a challenge and data generated through surveys is often unreliable and inaccurately reported. Monitoring nutrition markers in wastewater provides an unbiased source of information about people’s nutrition habits and empowers policymakers and public health professionals to target campaigns to encourage healthier habits.",
    },
  },
  {
    name: "Green spaces & cycling infrastructure",
    icon: <DirectionsBikeOutlinedIcon />,
    info: {
      overview:
        "Understanding how our greenspaces and ‘green’ transport infrastructure is impacting the health and wellbeing of the population would help make targeted infrastructure and development decisions to benefit communities.",
      what: "Monitoring for markers associated with mental and physical health including anti-depressant drug use, drugs associated with cholesterol and diabetes.",
      how: "Monitoring the wastewater in communities around the green spaces and ‘green infrastructure’",
      why: "Monitoring communities and areas for key health and wellbeing indicators provides us insight which helps inform infrastructure decision and creation of green spaces and ‘green’ transport infrastructure. It also helps measure the benefits conferred by these installations which allows more intelligent planning.",
    },
  },
  {
    name: "Care home",
    icon: <ElderlyWomanOutlinedIcon />,
    info: {
      overview:
        "Monitoring care homes is crucial to ensure protection and promotion of wellbeing of some of the most vulnerable people in our society.",
      what: "Monitoring for infectious disease markers and markers indicating physical and mental wellbeing.",
      how: "Site specific wastewater monitoring",
      why: "Improving and maintaining high standards for social care is crucial for public health. Monitoring the health of the most vulnerable in our population enables early interventions to prevent the spread of infectious diseases and better targeted initiatives to improve the physical and mental wellbeing of residents.",
    },
  },
  {
    name: "Prison",
    icon: <LocalPoliceOutlinedIcon />,
    info: {
      overview:
        "Prisons are a vital infrastructure where illicit drug use is often hard to monitor and infectious diseases can spread rapidly.",
      what: "Monitoring for illicit compounds such as the by-products of recreational drugs. Also monitoring key communicable infections including COVID-19, influenza, and norovirus.",
      how: "Monitoring the wastewater of prisons",
      why: "Illicit drug use in prisons can present a challenge for the rehabilitation of inmates. Early detection of these compounds can enable early interventions and improved rehabilitation of prisoners. Early detection of infectious diseases within prisons can improve inmate welfare.",
    },
  },
  {
    name: "Airport & ferries",
    icon: <AirplanemodeActiveOutlinedIcon />,
    info: {
      overview:
        "Travel corridors are a high risk for the spread of infectious diseases. Monitoring for the spread of potential high-risk pathogens in and out of the country provides an effective way of preventing infectious diseases spreading into the country.",
      what: "Known and unknown infectious pathogens",
      how: "Monitoring wastewater at transports hubs",
      why: "Identification of diseases before they enter the country and spread within the population is a useful way of preventing the next countrywide epidemic. Early detection enables source identification and early contact tracing to minimise the spread of infections within our communities.",
    },
  },
];
