import { createContext, useState, useContext, useEffect } from "react";
import {
  healthSubAreas,
  environmentSubAreas,
  communitySubAreas,
} from "../Pages/LandingPage/Components/SubArea/AreaButtons/SubAreasList.js";
import { useAreaSelectSettings } from "./AreaSelectContext";

const SubAreaContext = createContext();
const subAreas = {
  health: healthSubAreas,
  environment: environmentSubAreas,
  community: communitySubAreas,
};

export const SubAreaContextProvider = ({ children }) => {
  const [categorySelected, setCategorySelected] = useState("Introduction");
  const { areaSelected } = useAreaSelectSettings();
  const [currentSubArea, setCurrentSubArea] = useState([]);

  useEffect(() => {
    setCurrentSubArea(subAreas[areaSelected]);
  }, [areaSelected]);

  return (
    <SubAreaContext.Provider
      value={{
        categorySelected,
        setCategorySelected,
        currentSubArea,
      }}
    >
      {children}
    </SubAreaContext.Provider>
  );
};

export const useSubAreaSettings = () => {
  const context = useContext(SubAreaContext);

  if (context === undefined) {
    throw new Error(
      "useSubAreaSettings must be used within a SubAreaContextProvider",
    );
  }
  return context;
};
