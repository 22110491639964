import { Box, Button, Typography } from "@mui/material";
import { useSubAreaSettings } from "../../../../../Contexts/SubAreaContext";

export const AreaButtonsMobile = () => {
  const { currentSubArea, categorySelected, setCategorySelected } =
    useSubAreaSettings();

  return (
    <Box
      width={"100%"}
      height={"20%"}
      overflow={"scroll"}
      display={"flex"}
      justifyContent={"space-evenly"}
      alignItems={"center"}
      padding={"0px 50px"}
    >
      {currentSubArea.map((button) => {
        return (
          <Button
            key={button.name}
            sx={{
              height: "100%",
              minWidth: "100px",
              bgcolor:
                button.name.toLowerCase() === categorySelected.toLowerCase()
                  ? "#fffef1"
                  : "#fcefe5",
              border:
                button.name.toLowerCase() === categorySelected.toLowerCase()
                  ? 0
                  : 1,
              borderRadius: 0,
              borderColor: "#FBCBB0",
            }}
            onClick={() => setCategorySelected(button.name)}
          >
            <Box
              height={"100%"}
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {button.icon}
              <Typography
                fontWeight={"bold"}
                color={"#7A4191"}
                fontSize={"0.60rem"}
              >
                {button.name}
              </Typography>
            </Box>
          </Button>
        );
      })}
    </Box>
  );
};
