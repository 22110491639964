import { createContext, useContext, useState, useRef } from "react";

const AreaSelectContext = createContext();

export const AreaSelectContextProvider = ({ children }) => {
  const [islandRotate, setIslandRotate] = useState(true);
  const [overviewVisible, setOverviewVisible] = useState(false);
  const [cameraOrbit, setCameraOrbit] = useState("0deg 75deg 72%");
  const [areaSelected, setAreaSelected] = useState("");
  const [islandsVisible, setIslandsVisible] = useState(true); // TODO: rename this to something more desciptive e.g subMenuOpen
  const modelViewerRef = useRef();
  const areaCameraSettings = {
    environment: "0deg 75deg 50%",
    community: "90deg 75deg 50%",
    health: "250deg 75deg 50%",
  };
  const areas = ["environment", "community", "health"];

  const rotateToArea = (area) => {
    setCameraOrbit(areaCameraSettings[area]);
  };

  const onAreaClick = (area) => {
    const cameraOrbit = areaCameraSettings[area.toLowerCase()];
    if (!cameraOrbit) return;

    // The timeout is needed as a workaround to an issue where camera-controls + auto rotate
    // DO NOT update camera-orbit
    modelViewerRef.current.resetTurntableRotation();
    setTimeout(() => {
      setCameraOrbit(cameraOrbit);
    }, 0);
    setAreaSelected(area);
    setIslandRotate(false);
    setOverviewVisible(true);
  };

  const resetCamera = () => {
    if (areaSelected === "environment") {
      setCameraOrbit("360deg 75deg 72%");
    }

    if (areaSelected === "health") {
      setCameraOrbit("249deg 75deg 72%");
    }

    if (areaSelected === "community") {
      setCameraOrbit("89deg 75deg 72%");
    }
  };

  const onExitClick = (section) => {
    // resetCamera() Needed due to the camera-orbit issue otherwise the model will constantly stay on the selected annotation so we -1 to the rotation
    // minus means the model moves in the direction of the auto rotate
    setOverviewVisible(false);
    resetCamera();
    setIslandRotate(true);
    setAreaSelected("");
    if (section === "subArea") {
      setIslandsVisible(true);
    }
  };

  return (
    <AreaSelectContext.Provider
      value={{
        islandRotate,
        setIslandRotate,
        overviewVisible,
        setOverviewVisible,
        cameraOrbit,
        setCameraOrbit,
        areaSelected,
        setAreaSelected,
        modelViewerRef,
        onAreaClick,
        onExitClick,
        resetCamera,
        islandsVisible,
        setIslandsVisible,
        areas,
        rotateToArea,
      }}
    >
      {children}
    </AreaSelectContext.Provider>
  );
};

export const useAreaSelectSettings = () => {
  const context = useContext(AreaSelectContext);

  if (context === undefined) {
    throw new Error(
      "useAreaSelectSettings must be used within a AreaSelectContextProvider",
    );
  }
  return context;
};
