import { Drawer, Box, Typography, Button } from "@mui/material";

export const CustomDrawer = ({ open, setOpen }) => {
  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor="right">
      <Box
        width={"400px"}
        border={1}
        height={"100%"}
        bgcolor={"#fefbf7"}
        padding={"40px"}
      >
        <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
          <Button
            sx={{
              borderRadius: "40px",
              border: 2,
              fontSize: "1.5rem",
              width: "75px",
              borderColor: "#14586B",
              color: "#14586B",
              "&.MuiButton-root:hover": {
                borderRadius: "40px",
                border: 2,
                fontSize: "1.5rem",
                width: "75px",
                borderColor: "#14586B",
                color: "#14586B",
              },
            }}
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            X
          </Button>
        </Box>
        <Box
          paddingTop={"50px"}
          paddingBottom={"50px"}
          borderBottom={1}
          borderColor={"#9BDCDD"}
        >
          <Typography
            color={"#1c325b"}
            fontWeight={550}
            fontSize={"1.5rem"}
            paddingBottom={"10px"}
          >
            About the Programme
          </Typography>
          <Typography color={"#1c325b"} fontWeight={550} fontSize={"1.5rem"}>
            What's next? Out Future Direction
          </Typography>
        </Box>

        <Box
          paddingTop={"40px"}
          borderBottom={1}
          height={"45%"}
          borderColor={"#9BDCDD"}
        >
          <Typography paddingBottom={"20px"}>Visit Scanarios</Typography>
          <Typography
            fontWeight={600}
            fontSize={"1.5rem"}
            paddingBottom={"10px"}
          >
            Community
          </Typography>
          <Typography
            fontWeight={600}
            fontSize={"1.5rem"}
            paddingBottom={"10px"}
          >
            Environment
          </Typography>
          <Typography fontWeight={600} fontSize={"1.5rem"}>
            Health
          </Typography>
        </Box>

        <Box paddingTop={"20px"}>
          <Typography
            fontWeight={600}
            fontSize={"1.5rem"}
            paddingBottom={"10px"}
          >
            References
          </Typography>
          <Typography
            fontWeight={600}
            fontSize={"1.5rem"}
            paddingBottom={"10px"}
          >
            Contact Us
          </Typography>
          <Typography
            fontWeight={600}
            fontSize={"1.5rem"}
            paddingBottom={"35px"}
          >
            Accessibility Statement
          </Typography>
          <Typography color={"#406580"}>&copy; All Rights Reserved</Typography>
        </Box>
      </Box>
    </Drawer>
  );
};
