export const STYLING_1080p = {
  typography: {
    fontFamily: "Montserrat, Aerial",
  },
  app: {
    height: "100vh",
  },
  homePage: {
    flexDirection: "row",
  },
  aboutTheProgramme: {
    imgWidth: "30%",
    headingFontSize: "3rem",
    subHeadingFontSize: "1.75rem",
    subHeadingWidth: "60%",
  },
  aboutWastewater: {
    width: "30%",
    height: "100%",
    heading: "2rem",
    headingBottomPadding: "40px",
    subHeading: "2rem",
    exploreButtonTextSize: "2rem",
    paddingBottom: "0px",
  },
  howItCanBeUsed: {
    width: "30%",
    height: "100%",
    heading: "2rem",
    headingBottomPadding: "40px",
    subHeading: "2rem",
    exploreButtonTextSize: "2rem",
    paddingBottom: "0",
  },
  findOutMore: {
    paddingTop: "80px",
    width: "30%",
    height: "100%",
    heading: "2rem",
    headingPaddingTop: "0px",
    buttonHeight: "60px",
    buttonTextSize: "1rem",
    exploreButtonTextSize: "2rem",
    wrapperBoxPaddingTop: "50px",
    findOutButtonsWrapperBox: {
      height: "100%",
      paddingBottom: "35px",
    },
    spanStyling: {
      width: "20%",
      height: "75%",
    },
  },
  overview: {
    height: "calc(100% - 200px)",
    heading: "2.5rem",
    body: "1.25rem",
    width: "50%",
    top: "0",
    right: "0",
    paddingBottom: "180px",
    wrapperBox: {
      width: "75%",
      height: "60%",
      rotation: "rotate(-1deg)",
    },
    button: {
      fontSize: "1.25rem",
    },
  },
  exitButton: {
    fontSize: "1rem",
    iconSize: "1rem",
  },
  exploreButton: {
    bottom: 50,
    top: null,
    fontSize: "2rem",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  subAreaInfo: {
    height: "80%",
    width: "35%",
    bottom: null,
    top: 130,
    right: -5,
    heading: "3rem",
    subHeading: "2rem",
    body: "1.5rem",
    wrapperBox: {
      borderRadius: "0px",
      rotation: "rotate(-1deg)",
    },
    contentWrapperBox: {
      width: "calc(100% - 100px)",
      height: "calc(100% - 100px)",
      rotation: "none",
      padding: "50px",
    },
    categoryWrapper: {
      paddingBottom: "25px",
    },
  },
  categoriesNonMobile: {
    fontSize: "1.25rem",
  },
  nextScenarioNonMobile: {
    width: "35%",
    height: "150px",
    headingFontSize: "1.25rem",
    nextScenarioButton: {
      fontSize: "2rem",
      textSize: "1rem",
    },
  },
  redLine: {
    width: "76%",
  },
  areaButtons: {
    height: "75%",
    top: 125,
    fontSize: "1rem",
    minWidth: "300px",
    buttonHeight: "50px",
    backButtonFontSize: "1rem",
    backButtonHeight: "50px",
    backButtonMinWidth: "250px",
  },
  landingPage: {
    height: "100%",
  },
  scenarioSign: {
    top: 80,
    fontSize: "2.5rem",
    height: "70px",
  },
  modelViewer: {
    width: "100%",
    position: "unset",
    top: null,
  },
  annotation: {
    heightUnselected: "200px",
    heightSelected: "100px",
    signFontSize: "1.5rem",
    maxWidth: "200px",
  },
  topMenu: {
    imgSrc: "Assets/Svgs/Water Tales Logo.svg",
    imgHeight: "90%",
    topMenuHeight: "10%",
    padding: "40px",
    width: "calc(100% - 80px)",
  },
  aboutIsland: {
    width: "30%",
  },
  resourceIsland: {
    height: "25%",
    width: "30%",
    justifyContent: "flex-end",
    imgWidth: "40%",
  },
};
