import { useState } from "react";
import { Box, useTheme } from "@mui/material";
import "@google/model-viewer";
import { ModelViewer } from "./Components/ModelViewer";
import { Overview } from "./Components/Overview/Overview";
import { useAreaSelectSettings } from "../../Contexts/AreaSelectContext";
import { CustomDrawer } from "../../Components/CustomDrawer";
import { ExploreButton } from "./Components/ExploreButton";
import { TopMenu } from "./Components/TopMenu";
import { AboutIsland } from "./Components/AboutIsland";
import { ResourceIsland } from "./Components/ResourceIsland";
import { ScenarioSign } from "./Components/ScenarioSign";
import { AreaButtons } from "./Components/SubArea/AreaButtons/AreaButtons";
import { SubAreaInfo } from "./Components/SubArea/SubAreaInfo/SubAreaInfo";
import { SubAreaContextProvider } from "../../Contexts/SubAreaContext";
import { NextScenarioNonMobile } from "./Components/SubArea/NextScenario/NextScenarioNonMobile/NextScenarioNonMobile";
import { NextScenarioMobile } from "./Components/SubArea/NextScenario/NextScenarioMobile/NextScenarioMobile";
import { useScreenSize } from "../../Hooks/useScreenSize";

export const LandingPage = () => {
  const [open, setOpen] = useState(false);
  const { islandsVisible } = useAreaSelectSettings();
  const theme = useTheme();
  const screenSize = useScreenSize();
  return (
    <>
      <CustomDrawer open={open} setOpen={setOpen} />
      <Box
        width={"100%"}
        height={theme.landingPage.height}
        position={"relative"}
        backgroundColor={islandsVisible ? "#f8fffd" : "#FCEBEB"}
        sx={{ transition: "background-color 0.5s ease" }}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        overflow={"hidden"}
      >
        <ScenarioSign />
        <ModelViewer />
        <TopMenu setOpen={setOpen} />
        {islandsVisible && <AboutIsland />}
        <ExploreButton />
        {islandsVisible && <ResourceIsland />}
        <Overview />
        {!islandsVisible && (
          <SubAreaContextProvider>
            {screenSize !== "mobile" && <AreaButtons />}
            <SubAreaInfo />
            {screenSize === "mobile" ? (
              <NextScenarioMobile />
            ) : (
              <NextScenarioNonMobile />
            )}
          </SubAreaContextProvider>
        )}
      </Box>
    </>
  );
};
