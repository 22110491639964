import { useEffect, useState } from "react";
import "@google/model-viewer";
import { Annotation } from "./Annotation/Annotation";
import annotations from "./Annotation/annotations.json";
import { useAreaSelectSettings } from "../../../Contexts/AreaSelectContext";
import { Box, useTheme } from "@mui/material";
import { CircularProgressWithLabel } from "../../../Components/CircularProgressWithLabel";
import { useModelLoaded } from "../../../Contexts/ModelLoadedContext";
import { useScreenSize } from "../../../Hooks/useScreenSize";

export const ModelViewer = () => {
  const { cameraOrbit, islandRotate, modelViewerRef, islandsVisible } =
    useAreaSelectSettings();
  const { modelLoaded, setModelLoaded } = useModelLoaded();
  const [loadingProgress, setLoadingProgress] = useState(0);
  const theme = useTheme();
  const screenSize = useScreenSize();

  useEffect(() => {
    const modelViewerElement = modelViewerRef.current;
    const loading = (e) => {
      setLoadingProgress(Math.round(e.detail.totalProgress * 100));
      if (Math.round(e.detail.totalProgress * 100) === 100) {
        setModelLoaded(true);
      }
    };

    modelViewerElement.addEventListener("progress", loading);

    return () => {
      modelViewerElement.removeEventListener("progress", loading);
    };
  }, [modelViewerRef, setModelLoaded]);

  const setStyling = () => {
    if (screenSize === "mobile" && islandsVisible === false) {
      return {
        width: theme.modelViewer.width,
        height: "90%",
        display: "flex",
        position: theme.modelViewer.modelViewerAreaSelected.position,
        top: theme.modelViewer.modelViewerAreaSelected.top,
        zIndex: theme.modelViewer.modelViewerAreaSelected.zIndex,
        pointerEvents: theme.modelViewer.modelViewerAreaSelected.pointerEvents,
      };
    } else {
      return {
        width: theme.modelViewer.width,
        height: "90%",
        display: "flex",
        position: theme.modelViewer.position,
        top: theme.modelViewer.top,
      };
    }
  };

  return (
    <Box sx={setStyling()}>
      <model-viewer
        ref={modelViewerRef}
        style={{
          width: "100%",
          height: "100%",
          transition: "opacity 0.5s ease-in-out",
        }}
        src="Assets/Models/landing island.glb"
        alt="island"
        {...(islandRotate ? { "auto-rotate": "" } : "")}
        {...(islandRotate ? { "camera-controls": "" } : "")}
        auto-rotate-delay="500"
        disable-zoom
        min-camera-orbit="auto 73deg auto"
        max-camera-orbit="auto 73deg auto"
        interaction-prompt="none"
        disable-tap
        camera-orbit={cameraOrbit}
        exposure="1"
        tone-mapping="neutral"
        interpolation-decay={islandRotate ? 50 : 250}
      >
        {annotations.map((annotation, i) => (
          <Annotation
            key={annotation.text}
            slot={"hotspot-" + i + 1}
            dataPosition={annotation.dataPosition}
            dataNormal={annotation.dataNormal}
            dataVisibilityAttribute={annotation.dataVisibilityAttribute}
            text={annotation.text}
          />
        ))}
        <div
          slot="progress-bar"
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: modelLoaded ? "none" : "flex",
          }}
        >
          {!modelLoaded && (
            <CircularProgressWithLabel loadingProgress={loadingProgress} />
          )}
        </div>
      </model-viewer>
    </Box>
  );
};
