import { useState, useEffect } from "react";
import { Box, Typography, Button, useTheme } from "@mui/material/";
import overviews from "./overviews.json";
import { useAreaSelectSettings } from "../../../../Contexts/AreaSelectContext";
import { ExitButton } from "./ExitButton";

export const Overview = () => {
  const [overview, setOverview] = useState({ title: "", overview: "" });
  const { areaSelected, setAreaSelected, onAreaClick, overviewVisible } =
    useAreaSelectSettings();
  const theme = useTheme();

  useEffect(() => {
    const selectedOverview = overviews.find(
      (overview) => overview.title.toLocaleLowerCase() === areaSelected,
    );

    if (selectedOverview) {
      setOverview(selectedOverview);
    }
  }, [areaSelected]);

  const onClick = (area) => {
    onAreaClick(area);
    setAreaSelected(area);
  };

  return (
    <Box
      position={"absolute"}
      width={theme.overview.width}
      height={theme.overview.height}
      top={theme.overview.top}
      right={theme.overview.right}
      paddingTop={"20px"}
      paddingLeft={"10px"}
      display={overviewVisible ? "flex" : "none"}
      flexDirection={"column"}
      justifyContent={"flex-end"}
      alignItems={"flex-end"}
      paddingBottom={theme.overview.paddingBottom}
      overflow={"hidden"}
      sx={{
        pointerEvents: "none",
      }}
    >
      <Box
        borderRadius={"50px 0 0 50px"}
        width={theme.overview.wrapperBox.width}
        height={theme.overview.wrapperBox.height}
        bgcolor={"#fffef1"}
        boxShadow={"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"}
        sx={{
          transform: theme.overview.wrapperBox.rotation,
        }}
        display={"flex"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Box
          height={"80%"}
          width={"85%"}
          display={"flex"}
          justifyContent={"space-evenly"}
          flexDirection={"column"}
        >
          <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>
            <Typography
              color={"#7a4191"}
              fontWeight={"bold"}
              variant="h1"
              fontSize={theme.overview.heading}
            >
              {overview ? overview.title : ""}
            </Typography>
            <ExitButton />
          </Box>
          <Typography
            variant="h2"
            fontSize={theme.overview.body}
            fontWeight={500}
            color={"#14335E"}
          >
            {overview ? overview.text : ""}
          </Typography>
        </Box>

        <Box width={"90%"} height={"15%"} display={"flex"}>
          <Button
            sx={{
              flexGrow: 1,
              borderRadius: 0,
              color: "#7a4191",
              fontFamily: "Montserrat",
              fontSize: theme.overview.button.fontSize,
              fontWeight: "bold",
              bgcolor: areaSelected === "environment" ? "" : "#FFEFE4",
              borderBottomLeftRadius: "15px",
              pointerEvents: "auto",
            }}
            onClick={() => onClick("environment")}
          >
            Environment
          </Button>
          <Button
            sx={{
              flexGrow: 1,
              marginRight: "5px",
              marginLeft: "5px",
              bgcolor: areaSelected === "community" ? "" : "#FFEFE4",
              borderRadius: 0,
              color: "#7a4191",
              fontFamily: "Montserrat",
              fontSize: theme.overview.button.fontSize,
              fontWeight: "bold",
              pointerEvents: "auto",
            }}
            onClick={() => onClick("community")}
          >
            Community
          </Button>
          <Button
            sx={{
              flexGrow: 1,
              bgcolor: areaSelected === "health" ? "" : "#FFEFE4",
              borderRadius: 0,
              color: "#7a4191",
              fontFamily: "Montserrat",
              fontSize: theme.overview.button.fontSize,
              fontWeight: "bold",
              pointerEvents: "auto",
            }}
            onClick={() => onClick("health")}
          >
            Health
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
