import { Box, Typography, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const HowItCanBeUsed = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <span
      style={{
        width: theme.howItCanBeUsed.width,
        height: theme.howItCanBeUsed.height,
        minHeight: theme.howItCanBeUsed.height,
        filter: "drop-shadow(0px 1.5px 2px #000000)",
        paddingBottom: theme.aboutWastewater.paddingBottom,
      }}
    >
      <Box
        width={"100%"}
        height={"100%"}
        bgcolor={"#FFFAEA"}
        sx={{
          clipPath: "polygon(0 0, 100% 14%, 100% 100%, 0% 100%)",
          transform: "rotate(-1deg)",
        }}
      >
        <Box
          paddingTop={"50px"}
          sx={{ transform: "rotate(1deg)" }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
          height={"calc(100% - 50px)"}
          flexDirection={"column"}
          position={"relative"}
        >
          <Box width={"100%"} height={"100%"} position={"absolute"} right={-2}>
            <img
              style={{ objectFit: "cover" }}
              height={"100%"}
              width={"100%"}
              src={"Assets/Svgs/island_large.svg"}
              alt="about WBE"
            />
          </Box>

          <Box
            position={"absolute"}
            top={0}
            left={0}
            width={"100%"}
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
          >
            <Box
              height={"calc(75% - 60px)"}
              paddingTop={"60px"}
              paddingLeft={"50px"}
              paddingRight={"50px"}
            >
              <Typography
                variant={"h1"}
                fontWeight={"bold"}
                color={"#406580"}
                paddingBottom={theme.howItCanBeUsed.headingBottomPadding}
                fontSize={theme.howItCanBeUsed.heading}
              >
                HOW WASTEWATER MONITORING CAN BE USED
              </Typography>

              <Typography
                variant={"h2"}
                color={"#406580"}
                fontWeight={"500"}
                fontSize={theme.howItCanBeUsed.subHeading}
              >
                Come and explore the full range of how wastewater monitoring can
                be used in the future.
              </Typography>
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                sx={{
                  width: "75%",
                  backgroundColor: "#7127ba",
                  fontSize: theme.howItCanBeUsed.exploreButtonTextSize,
                  borderRadius: "50px",
                  textTransform: "none",
                  "&.MuiButton-root:hover": {
                    backgroundColor: "#14586B",
                    color: "white",
                  },
                }}
                onClick={() => navigate("/landing-page")}
              >
                Explore
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </span>
  );
};
