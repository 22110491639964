import { Box, useTheme } from "@mui/material";
import { useModelLoaded } from "../../../Contexts/ModelLoadedContext";
import { keyframes } from "@mui/material";

export const ResourceIsland = () => {
  const { modelLoaded } = useModelLoaded();
  const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;
  const theme = useTheme();

  return (
    <Box
      position={"absolute"}
      width={theme.resourceIsland.width}
      height={theme.resourceIsland.height}
      top={"0"}
      right={"0"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={theme.resourceIsland.justifyContent}
      alignItems={"center"}
      marginTop={"7%"}
      sx={{
        opacity: modelLoaded ? 1 : 0.25,
        transition: "opacity 0.5s ease-in-out",
        animation: `${floatAnimation} 4s ease-in-out infinite`,
        pointerEvents: "none",
      }}
    >
      <img
        width={theme.resourceIsland.imgWidth}
        src="Assets/Svgs/Resources.png"
        alt="Resources island"
      />
    </Box>
  );
};
