import { Box, Typography } from "@mui/material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useAreaSelectSettings } from "../../../../../../Contexts/AreaSelectContext";
import { useSubAreaSettings } from "../../../../../../Contexts/SubAreaContext";

export const NextScenarioMobile = () => {
  const { areaSelected, setAreaSelected, areas, rotateToArea, onExitClick } =
    useAreaSelectSettings();
  const { setCategorySelected } = useSubAreaSettings();
  let selectedIndex = areas.indexOf(areaSelected);

  const setArea = (direction) => {
    selectedIndex =
      direction === "back"
        ? selectedIndex === 0
          ? 2
          : selectedIndex - 1
        : selectedIndex === 2
          ? 0
          : selectedIndex + 1;

    setAreaSelected(areas[selectedIndex]);
    rotateToArea(areas[selectedIndex]);
    setCategorySelected("Introduction");
  };

  return (
    <Box
      height={"6%"}
      width={"100%"}
      bgcolor={"#f9fffd"}
      position={"absolute"}
      bottom={0}
      display={"flex"}
    >
      <Box
        width={"40%"}
        border={1}
        borderColor={"#9BDCDD"}
        height={"100%"}
        display={"flex"}
        justifyContent={"space-around"}
        alignItems={"center"}
      >
        <KeyboardReturnIcon sx={{ color: "red" }} />
        <Typography
          onClick={() => onExitClick("subArea")}
          fontSize={"0.8rem"}
          color={"#14335E"}
        >
          Back to Home Island
        </Typography>
      </Box>
      <Box
        width={"60%"}
        border={1}
        borderColor={"#9BDCDD"}
        height={"100%"}
        display={"flex"}
        justifyContent={"space-around"}
        alignItems={"center"}
      >
        <Typography fontSize={"0.6rem"} fontWeight={"bold"} color={"#14335E"}>
          {areaSelected.toUpperCase()} SCENARIO
        </Typography>
        <ArrowBackIcon
          onClick={() => setArea("back")}
          sx={{ color: "red", width: "20%", height: "80%" }}
        />
        <ArrowForwardIcon
          onClick={() => setArea("forward")}
          sx={{ color: "red", width: "20%", height: "80%" }}
        />
      </Box>
    </Box>
  );
};
