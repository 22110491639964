import { Box, Button, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAreaSelectSettings } from "../../../../Contexts/AreaSelectContext";

export const ExitButton = () => {
  const { onExitClick } = useAreaSelectSettings();
  const theme = useTheme();
  return (
    <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
      <Button
        sx={{
          fontSize: theme.exitButton.fontSize,
          backgroundColor: "#7a4191",
          fontWeight: "bold",
          borderRadius: "50px",
          textTransform: "none",
          pointerEvents: "auto",
          "&.MuiButton-root:hover": {
            backgroundColor: "#14586B",
            color: "white",
          },
        }}
        variant="contained"
        endIcon={<CloseIcon style={{ fontSize: theme.exitButton.iconSize }} />}
        onClick={() => onExitClick("area")}
      >
        Exit
      </Button>
    </Box>
  );
};
