import "@google/model-viewer";
import "./Annotation.css";
import { useAreaSelectSettings } from "../../../../Contexts/AreaSelectContext";
import { useTheme } from "@mui/material";

export const Annotation = ({
  slot,
  dataPosition,
  dataNormal,
  dataVisibilityAttribute,
  text,
}) => {
  const { onAreaClick, areaSelected } = useAreaSelectSettings();
  const theme = useTheme();
  // style prop is needed as we need to apply the focused styling programmatically
  // for the annotations

  return (
    <button
      className="Hotspot"
      slot={slot}
      data-position={dataPosition}
      data-normal={dataNormal}
      data-visibility-attribute={dataVisibilityAttribute}
      onMouseDown={() => onAreaClick(text.toLowerCase())}
      style={{
        backgroundColor:
          areaSelected === text.toLowerCase() ? "#aaaaaa" : "#FFEFE4",
        border:
          areaSelected === text.toLowerCase()
            ? "4px solid rgb(0, 128, 200)"
            : undefined,
        height:
          areaSelected === text.toLowerCase()
            ? theme.annotation.heightSelected
            : theme.annotation.heightUnselected,
        outline: areaSelected === text.toLowerCase() ? "none" : undefined,
        width: areaSelected === text.toLowerCase() ? "32px" : undefined,
      }}
    >
      <div
        className="HotspotAnnotation"
        style={{
          maxWidth: theme.annotation.maxWidth,
          background:
            areaSelected === text.toLowerCase() ? "#7a4191" : undefined,
          color: areaSelected === text.toLowerCase() ? "#fff" : undefined,
          transform:
            areaSelected === text.toLowerCase() ? "rotate(6deg)" : undefined,
          fontSize: theme.annotation.signFontSize,
        }}
      >
        {text}
      </div>
    </button>
  );
};
