import { Box, useTheme } from "@mui/material";
import { AboutTheProgramme } from "./Components/AboutTheProgramme";
import { AboutWastewater } from "./Components/AboutWastewater";
import { HowItCanBeUsed } from "./Components/HowItCanBeUsed";
import { FindOutMore } from "./Components/FindOutMore";

export const HomePage = () => {
  const theme = useTheme();
  return (
    <Box
      width={"100%"}
      height={"100%"}
      backgroundColor={"#f8fffd"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <AboutTheProgramme />
      <Box
        width={"calc(100% - 60px)"}
        height={"70%"}
        padding={"30px"}
        display={"flex"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        flexDirection={theme.homePage.flexDirection}
      >
        <AboutWastewater />
        <HowItCanBeUsed />
        <FindOutMore />
      </Box>
    </Box>
  );
};
