import { useMediaQuery } from "@mui/material";

export const useScreenSize = () => {
  const isMobile = useMediaQuery("(max-width: 480px)");
  const is720p = useMediaQuery("(min-width: 481px) and (max-width: 1280px)");
  const is1080p = useMediaQuery("(min-width: 1281px) and (max-width: 1920px)");
  const is4k = useMediaQuery("(min-width: 1921px)");

  if (isMobile) return "mobile";
  if (is720p) return "720p";
  if (is1080p) return "1080p";
  if (is4k) return "4k";
};
