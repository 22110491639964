import { Box, Typography, useTheme } from "@mui/material";
import { useModelLoaded } from "../../../Contexts/ModelLoadedContext";

export const ScenarioSign = () => {
  const { modelLoaded } = useModelLoaded();
  const theme = useTheme();

  return (
    <Box
      height={"50%"}
      width={"20px"}
      bgcolor={"#AAAAAA"}
      position={"absolute"}
      top={theme.scenarioSign.top}
      display={"flex"}
      justifyContent={"center"}
      borderRadius={"15px"}
      sx={{
        opacity: modelLoaded ? 1 : 0.25,
        transition: "opacity 0.5s ease-in-out",
      }}
    >
      <Box
        paddingLeft={"30px"}
        paddingRight={"30px"}
        width={400}
        height={theme.scenarioSign.height}
        bgcolor={"#EA2E5B"}
        borderRadius={"15px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        color={"#fff"}
        sx={{
          transform: "rotate(-6deg)",
        }}
      >
        <Typography
          // fontSize={"2.5rem"}
          fontSize={theme.scenarioSign.fontSize}
          fontWeight={"bold"}
          fontFamily={"Montserrat Alternates"}
        >
          Scenarios
        </Typography>
      </Box>
    </Box>
  );
};
