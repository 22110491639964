import "./App.css";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { LandingPage } from "./Pages/LandingPage/LandingPage";
import { HomePage } from "./Pages/HomePage/HomePage";
import { Box } from "@mui/material";
import { AreaSelectContextProvider } from "./Contexts/AreaSelectContext";
import { createTheme, ThemeProvider } from "@mui/material";
import { ModelLoadedContextProvider } from "./Contexts/ModelLoadedContext";
import { useScreenSize } from "./Hooks/useScreenSize";
import { STYLING_720P } from "./Styling/720p";
import { STYLING_1080p } from "./Styling/1080p";
import { STYLING_MOBILE } from "./Styling/Mobile";
import { STYLING_WIDESCREEN } from "./Styling/Widescreen";

function App() {
  const screenSize = useScreenSize();
  const getStyling = () => {
    switch (screenSize) {
      case "mobile":
        return STYLING_MOBILE;
      case "720p":
        return STYLING_720P;
      case "1080p":
        return STYLING_1080p;
      case "4k":
        return STYLING_WIDESCREEN;
      default:
        return STYLING_1080p;
    }
  };

  const theme = createTheme(getStyling());

  return (
    <ThemeProvider theme={theme}>
      <Box
        height={theme.app.height}
        width={"100vw"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          height={"100%"}
          width={"100%"}
          maxWidth={"3840px"}
          // maxHeight={"1080px"}
        >
          <Router>
            <AreaSelectContextProvider>
              <ModelLoadedContextProvider>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/landing-page" element={<LandingPage />} />
                </Routes>
              </ModelLoadedContextProvider>
            </AreaSelectContextProvider>
          </Router>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
