import { Box, useTheme } from "@mui/material";

export const RedLine = () => {
  const theme = useTheme();
  return (
    <Box
      height={"1px"}
      width={theme.redLine.width}
      position={"absolute"}
      top={37}
      left={72}
    >
      <Box
        sx={{
          borderBottom: "1px 0",
          backgroundImage:
            "linear-gradient(to right, red 50%, transparent 50%)",
          backgroundSize: "12px 1px",
          backgroundRepeat: "repeat-x",
          paddingTop: "50px",
        }}
      />
    </Box>
  );
};
