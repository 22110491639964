import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSubAreaSettings } from "../../../../../Contexts/SubAreaContext";
import { CategoriesNonMobile } from "./CategoriesNonMobile";
import { useScreenSize } from "../../../../../Hooks/useScreenSize";
import { CategoriesMobile } from "./CategoriesMobile";
import { AreaButtonsMobile } from "../AreaButtons/AreaButtonsMobile";

export const SubAreaInfo = () => {
  const { categorySelected, currentSubArea } = useSubAreaSettings();
  const [infoTitle, setInfoTitle] = useState("overview");
  const [infoBody, setInfoBody] = useState("");
  const theme = useTheme();
  const screenSize = useScreenSize();

  useEffect(() => {
    currentSubArea.forEach((subArea) => {
      if (categorySelected === subArea.name) {
        setInfoBody(subArea.info[infoTitle]);
      }
    });
  }, [infoTitle, categorySelected, currentSubArea]);

  return (
    <span
      style={{
        width: theme.subAreaInfo.width,
        height: theme.subAreaInfo.height,
        filter: "drop-shadow(0px 1.5px 2px #000000)",
        position: "absolute",
        top: theme.subAreaInfo.top,
        right: theme.subAreaInfo.right,
        bottom: theme.subAreaInfo.bottom,
      }}
    >
      <Box
        borderRadius={theme.subAreaInfo.wrapperBox.borderRadius}
        width={"100%"}
        height={"85%"}
        bgcolor={"#fffef1"}
        sx={{
          transform: theme.subAreaInfo.wrapperBox.rotation,
          clipPath: "polygon(0 11%, 100% 0, 100% 100%, 0% 100%)",
        }}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Box
          height={theme.subAreaInfo.contentWrapperBox.height}
          width={theme.subAreaInfo.contentWrapperBox.width}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          sx={{ transform: theme.subAreaInfo.contentWrapperBox.rotation }}
          padding={theme.subAreaInfo.contentWrapperBox.padding}
        >
          <Box paddingBottom={theme.subAreaInfo.categoryWrapper.paddingBottom}>
            {screenSize === "mobile" && (
              <CategoriesMobile
                infoTitle={infoTitle}
                setInfoTitle={setInfoTitle}
              />
            )}

            <Typography
              fontFamily={"Montserrat Alternates"}
              color={"#7a4191"}
              fontWeight={"bold"}
              variant="h1"
              fontSize={theme.subAreaInfo.heading}
              paddingBottom={"10px"}
            >
              {categorySelected}
            </Typography>
            <Box height={"5px"} width={"10%"} bgcolor={"#9BDCDD"} />
            <Typography
              color={"#EA2E5B"}
              fontWeight={"600"}
              fontSize={theme.subAreaInfo.subHeading}
              variant="h2"
            >
              {infoTitle.charAt(0).toUpperCase() + infoTitle.slice(1)}
            </Typography>
          </Box>
          <Typography
            variant="h3"
            color={"#14335E"}
            fontWeight={500}
            fontSize={theme.subAreaInfo.body}
          >
            {infoBody}
          </Typography>
        </Box>
        {screenSize === "mobile" && <AreaButtonsMobile />}
        {screenSize !== "mobile" && (
          <CategoriesNonMobile
            infoTitle={infoTitle}
            setInfoTitle={setInfoTitle}
          />
        )}
      </Box>
    </span>
  );
};
