export const STYLING_MOBILE = {
  typography: {
    fontFamily: "Montserrat, Aerial",
  },
  app: {
    height: "100%",
  },
  homePage: {
    flexDirection: "column",
  },
  aboutTheProgramme: {
    imgWidth: "100%",
    headingFontSize: "1.5rem",
    subHeadingFontSize: "1.25rem",
    subHeadingWidth: "100%",
  },
  aboutWastewater: {
    width: "100%",
    height: "400px",
    heading: "1.25rem",
    headingBottomPadding: "20px",
    subHeading: "1.25rem",
    exploreButtonTextSize: "1.25rem",
    paddingBottom: "20px",
  },
  howItCanBeUsed: {
    width: "100%",
    height: "400px",
    heading: "1.25rem",
    headingBottomPadding: "10px",
    subHeading: "1.25rem",
    exploreButtonTextSize: "1.25rem",
    paddingBottom: "20px",
  },
  findOutMore: {
    paddingTop: "0px",
    heading: "1.25rem",
    buttonHeight: "38px",
    exploreButtonTextSize: "1.25rem",
    wrapperBoxPaddingTop: "10px",
    findOutButtonsWrapperBox: {
      height: null,
      paddingBottom: 0,
    },
    spanStyling: {
      width: "100%",
      height: "400px",
    },
  },
  overview: {
    height: "calc(100% - 20px)",
    heading: "2rem",
    body: "1rem",
    width: "100%",
    top: "0",
    right: "0",
    paddingBottom: "0px",
    wrapperBox: {
      width: "100%",
      height: "40%",
      rotation: "none",
    },
    button: {
      fontSize: "1rem",
    },
  },
  exitButton: {
    fontSize: "1rem",
    iconSize: "1rem",
  },
  exploreButton: {
    bottom: null,
    top: "20%",
    fontSize: "1.5rem",
    paddingLeft: "100px",
    paddingRight: "100px",
  },
  subAreaInfo: {
    height: "50%",
    width: "101%",
    bottom: -10,
    top: null,
    right: -5,
    heading: "2rem",
    subHeading: "1.25rem",
    body: "0.9rem",
    wrapperBox: {
      borderRadius: "0px",
      rotation: "none",
    },
    contentWrapperBox: {
      width: "95%",
      height: "100%",
      rotation: "none",
      padding: "0px",
    },
    categoryWrapper: {
      paddingBottom: "5px",
    },
  },
  categoriesNonMobile: {
    fontSize: "1.25rem",
  },
  redLine: {
    width: "75%",
  },
  areaButtons: {
    height: "75%",
    top: 125,
    fontSize: "1rem",
    minWidth: "300px",
    buttonHeight: "50px",
  },
  landingPage: {
    height: "100vh",
  },
  scenarioSign: {
    top: "20%",
    fontSize: "2.5rem",
    height: "70px",
  },
  modelViewer: {
    width: "100%",
    position: "unset",
    top: 0,
    modelViewerAreaSelected: {
      position: "absolute",
      top: "-14%",
      zIndex: 1,
      pointerEvents: "none",
    },
  },
  annotation: {
    heightUnselected: "125px",
    heightSelected: "100px",
    signFontSize: "1.5rem",
    maxWidth: "200px",
  },
  topMenu: {
    imgSrc: "Assets/Svgs/Water Tales Logo Mobile.png",
    imgHeight: "70%",
    topMenuHeight: "10%",
    padding: "20px",
    width: "calc(100% - 40px)",
  },
  aboutIsland: {
    width: "50%",
  },
  resourceIsland: {
    height: "90%",
    width: "50%",
    justifyContent: "flex-end",
    imgWidth: "75%",
  },
};
