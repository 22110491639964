import { Button, Box, useTheme } from "@mui/material";
import { useAreaSelectSettings } from "../../../Contexts/AreaSelectContext";

export const ExploreButton = () => {
  const {
    areaSelected,
    setIslandRotate,
    setOverviewVisible,
    overviewVisible,
    setIslandsVisible,
  } = useAreaSelectSettings();
  const theme = useTheme();

  const onClick = () => {
    setOverviewVisible(false);
    setIslandRotate(false);
    setIslandsVisible(false);
  };

  return (
    <Box
      display={overviewVisible ? "flex" : "none"}
      sx={{
        position: "absolute",
        bottom: theme.exploreButton.bottom,
        top: theme.exploreButton.top,
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Button
        variant="contained"
        sx={{
          paddingLeft: theme.exploreButton.paddingLeft,
          paddingRight: theme.exploreButton.paddingRight,
          backgroundColor: "#7a4191",
          fontSize: theme.exploreButton.fontSize,
          fontFamily: "Montserrat Alternates",
          borderRadius: "50px",
          textTransform: "none",
          "&.MuiButton-root:hover": {
            backgroundColor: "#14586B",
            color: "white",
          },
        }}
        onClick={() => onClick()}
      >
        {`Explore ${areaSelected.charAt(0).toUpperCase() + areaSelected.slice(1)}`}
      </Button>
    </Box>
  );
};
